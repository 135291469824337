@import "variables";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $overlay-color;
  transition: opacity $cart-toggle-speed;
  z-index: $base-z-index;
  opacity: 0;

  &.open {
    width: 100vw;
    height: 100vh;
    opacity: $overlay-opacity;
  }

  &.closed {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
