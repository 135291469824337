@import "../variables.scss";

.wrapper {
  //display: flex;
  //justify-items: flex-start;
  //padding-top: 5px;

    .qty {
        padding-top: 6px;
        .label {
            font-family: $font-maax-medium;
        }
        .label,
        .value {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.42px;
        }
    }
}
