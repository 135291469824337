@import "variables";

.wrapper {
  background: $color-bauz-light;
  font-family: $font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  width: 100%;
  @media screen and (min-width: $mobileBp) {
      max-width: $minicart-width;
  }
  box-sizing: border-box;
  transition: right ease-in-out $cart-toggle-speed;
  color: $text-color;
  display: flex;
  flex-direction: column;


  &.miniCart {
    overflow-y: auto;
    z-index: $base-z-index + 1;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;

    &.close {
      right: -100vw;
    }

    &.open {

    }
  }

  &.cartSummary {

  }

  .loading {
    padding-top: 40%;

    .spinner {
      text-align: center;
    }

    .text {
      text-align: center;
    }
  }

  .cartHeader {

  }

  &.miniCart {
    .cartBody {
      padding: $wrapper-v-padding $wrapper-h-padding $wrapper-v-padding $wrapper-h-padding;
    }
  }

  .cartBody {
    flex: 0 0 auto;

    .stickyBlock {
      margin-inline: -12px;
      position: sticky;
      bottom: 0;
      background: #fff;
      padding-bottom: 45px;

      @supports (-webkit-touch-callout: none) {
          padding-bottom: 85px;
      }

      @media screen and (min-width: 1024px) {
          padding-bottom: 0;
      }

      .ctaWrapper {
          padding-inline: $wrapper-h-padding;
      }
      .totals {
        margin-inline: 0;
      }
    }

    .totals {
        background: white;
        margin-inline: -$wrapper-h-padding;
        padding: 14px 24px;
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.25) 0px -5px 25px -12px;
    }

    .proceedCheckout {
      margin: 0;

      &:disabled {
        opacity: .2;
      }
    }

    .suggestions {
      margin-top: $suggestions-block-spacing;

      .label {
        font-family: $font-maax-medium;
        margin-bottom: 42px;
        text-align: center;
        font-size: 38px;
        line-height: 38px;
      }
    }
  }

  .order_summary {
    display: block;
    font-family: $font-maax-medium;
    font-size: 26px;
    line-height: 26px;
    letter-spacing: -1.14px;
    margin-bottom: 20px;
    text-align: center;

    @media screen and (min-width: $mobileBp) {
        text-align: left;
    }
  }

    .cmsBlock {
        padding: 1rem;
        font-family: $font-maax-medium;
        font-size: 19px;
        line-height: 23px;
        letter-spacing: -0.57px;
        border-radius: 15px;
        margin-bottom: 12px;

        a {
            color: white
        }
    }
}
