@import "../variables";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: $totals-v-spacing;

  .label {
    flex: 1 0 calc(100% - 100px);
    font-family: $font-maax-medium;
    font-size: 17px;
    line-height: 18px;
    letter-spacing: -0.51px;
  }

  .amount {
    flex: 0 0 $totals-width;
    text-align: right;
    font-family: $font-maax-medium;
    font-size: 17px;
    line-height: 18px;
    letter-spacing: -0.57px;
  }


  &.discount {
    .label, .amount {
      color: $color-success-green
    }
  }

  &.shipping {
  }

  &.subtotal {
  }

  &.grandTotal {
    .label {
      font-size: 19px;
      line-height: 24px;
      flex: 1;
      small {
        font-family: $font-maax-regular;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.75px;
      }
    }
    .amount {
      font-size: 19px;
      line-height: 24px;
      color: $color-bauz-red;
    }
  }
}
