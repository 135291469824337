@import "variables";

.wrapper {
    p {
        margin: 0 0 10px 0;
    }

    z-index: 100;
    padding: $blocks-base-v-spacing $wrapper-h-padding $wrapper-v-padding $wrapper-h-padding;

    h1 {
        display: flex;
        align-items: center;
        margin: 0 0 10px 0;
        color: #333;
        font-size: 110%;
        font-weight: bold;
        text-transform: uppercase;
    }
}
