$font-maax-regular: 'Maax Regular', sans-serif;
$font-maax-medium: 'Maax Medium', sans-serif;
$font-maax-bold: 'Maax Bold', sans-serif;
$color-bauz-darker: #2E2E2E;
$color-bauz-dark: #454545;
$color-bauz-gray: #7E888C;
$color-bauz-lightgray: #C2D1D8;
$color-bauz-lightergray: #E6EAED;
$color-bauz-gradient-start: #CDB5B7;
$color-bauz-gradient-end: #C2D1D8;
$color-bauz-red: #FC4935;
$color-bauz-darkred: #C44234;
$color-bauz-lightred: #FECEC9;
$color-bauz-light: #F7F7F7;
$color-success-green: #06B981;

$mobileBp: 1024px;

$font-family: $font-maax-regular;
$text-color: $color-bauz-darker;
$base-font-size: 15px;
$base-line-height: 20px;

$base-z-index: 1000;

$overlay-color: black;
$overlay-opacity: .7;

$buttons-color: #a00000;
$buttons-text-color: white;
$buttons-font: $font-family;

$strong-divider-color: black;
$divider-color: #b5b5b5;

$login-background-color: #f0f0f0;

$header-height: 65px;
$header-height-desktop: 45px;
$minicart-width: 430px;
$thumbnail-width: 75px;
$thumbnail-height: 90px;

$wrapper-v-padding: 0px;
$wrapper-h-padding: 12px;

$blocks-base-v-spacing: 12px;

$totals-v-spacing: 15px;

$cart-toggle-speed: .5s;

$totals-width: 100px;

$close-button-color: $buttons-color;
$trash-icon-color: #b5b5b5;

$cards-padding: 15px;
$cards-color: #f9e6cc;
$product-cards-height: 120px;

$suggestions-block-spacing: 50px;
