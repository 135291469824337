@import "variables";

.wrapper {
  .message {
    font-size: 80%;
    padding: 10px;

    &.error {
      background: #ffc4c4;
      color: black;
    }

    &.warning {
      background: lightyellow;
      color: black;
    }

    &.success {
      background: #bbe8c0;
      color: black;
    }

    a, a:visited, a:hover {
      color: #333333;
      text-decoration: underline;
    }
  }
}
