@import "variables";

.wrapper {
  margin-bottom: $totals-v-spacing;
  display: flex;

  .coupon {
    text-transform: uppercase;
    font-weight: bold;
    flex: 0 1 auto;
    margin-right: 15px;
  }

  .remove {
    display: flex;
    flex: 0 0 200px;
    cursor: pointer;

    .label {
      flex: 0 0 auto;
      margin-right: 5px;
    }

    .icon {
      flex: 0 0 auto;
    }

    &.disabled {
      opacity: .5;
      cursor: auto;
    }
  }
}
