@import "../variables";

.wrapper {
    cursor: pointer;
    background: white;
    border-radius: 15px;
    font-size: 90%;
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 21px 10px;
    text-decoration: none;
    color: $color-bauz-dark;

    .picture {
        flex: 0 0 $thumbnail-width;
        margin-right: 10px;

        img {
            width: $thumbnail-width;
            height: auto;
            object-fit: cover;
        }
    }

    .details {
        position: relative;
        flex: auto;

        > * {
            margin-bottom: 2px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .price {
            font-family: $font-maax-medium;
            font-size: 19px;
            line-height: 25px;
            letter-spacing: -0.57px;
            color: $color-bauz-red;
            padding-top: 3px;
        }

        .brand {
            font-family: $font-maax-bold;
            text-transform: uppercase;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.36px;
        }
    }
}
