@import "variables";

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: $totals-v-spacing;

    .label {
        flex: 1 0 calc(100% - 100px);
        display: flex;
        flex-direction: column;
        font-family: $font-maax-medium;
        font-size: 17px;
        line-height: 20px;
        letter-spacing: -0.51px;
    }

    .amount {
        flex: 0 0 $totals-width;
        text-align: right;
        font-family: $font-maax-medium;
        font-size: 19px;
        line-height: 25px;
        letter-spacing: -0.57px;
    }

    .remove {
        padding: 5px 0;
        display: flex;
        flex: 0 0 auto;
        align-self: flex-start;
        cursor: pointer;

        .remove-label {
            font-family: $font-maax-regular;
            flex: 0 0 auto;
            margin-right: 5px;
        }

        .remove-icon {
            height: 18px;
            flex: 0 0 auto;
        }
    }
}
