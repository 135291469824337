@import "variables";

.wrapper {
  margin-top: 8px;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.75px;

  .price {
    color: $color-bauz-red;
    font-size: 16px;
    line-height: 16px;
    font-family: $font-maax-medium;
    letter-spacing: -0.45px;
  }

  .logo {
    display: inline;
    width: 80px;
    vertical-align: middle;
  }
}
