@import "variables";

//.wrapper {
    //background-color: $buttons-color;
    //color: $buttons-text-color;
    //font-family: $buttons-font;
    //border: 0;
    //padding: 5px 0;
    //
    //transition: all .3s ease-in-out;

    .btn {
        margin-top: 44px;
        margin-bottom: 44px;
        --webkit-appearance: none;
        --moz-appearance: none;
        border: none;
        background: none;
        width: 100%;
        text-align: center;
        justify-content: center;
        display: inline-flex;
        transform: var(--tw-transform);
        align-items: center;
        border-radius: 9999px;
        background-color: transparent;
        background-repeat: no-repeat;
        font-size: 23px;
        line-height: 25px;
        font-family: $font-maax-medium;
        padding: 1.5rem 2rem;
        letter-spacing: -0.69px;
        color: white;
        cursor: pointer;
        transition: all .3s ease-in-out;
    }

    .primary {
        mask: var(--bauz-btn-bg) ;
        -webkit-mask: var(--bauz-btn-bg);
        background-color: $color-bauz-red;
        &:hover {
            background-color: $color-bauz-darkred;
        }
    }

    .secondary {
        mask: var(--bauz-btn-bg) ;
        -webkit-mask: var(--bauz-btn-bg);
        background-color: $color-bauz-dark;
        &:hover {
            background-color: $color-bauz-darker;
        }
    }
//}
