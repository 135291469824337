@import "variables";

.wrapper {
  background: white;
  border-radius: 15px;
  font-size: 90%;
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 21px 10px;

  .picture {
    flex: 0 0 $thumbnail-width;
    margin-right: 10px;
    img {
      width: $thumbnail-width;
      height: auto;
      object-fit: cover;
    }
  }

  .outOfStock {
    line-height: 26px;
    color: darkred;
  }

  .details {
    position: relative;
    flex: auto;

    > * {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .rowTotal {
      font-family: $font-maax-medium;
      font-size: 19px;
      line-height: 25px;
      letter-spacing: -0.57px;
      color: $color-bauz-red;
      padding-top: 3px;
    }

    .brand {
      font-family: $font-maax-bold;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.36px;
    }

    .name {
      font-family: $font-maax-medium;
      font-size: 19px;
      line-height: 23px;
      letter-spacing: -0.57px;
    }

    .sku {
      font-size: 120%;
    }

    .specialPrice {
      margin: 5px 0;
      background-color: gray;
      color: white;
      padding: 2px 5px 3px 5px;
      border-radius: 4px;
      text-transform: uppercase;
      font-size: 80%;
      font-weight: bold;
      display: inline-block;
    }

    .remove {
      cursor: pointer;
      position: absolute;
      right: 3px;
      top: -6px;

      svg {
        width: 16px;
        height: 16px;
      }

      &.disabled {
        opacity: 0.5;
        cursor: auto;
      }
    }
  }

  .option {
    padding-top: 6px;
    &__label {
      font-family: $font-maax-medium;
    }
    &__label,
    &__value {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.42px;
    }
  }
}
