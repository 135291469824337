@import "../variables";

.wrapper {
    display: flex;

    .count {
        font-size: 11px;
        letter-spacing: -0.33px;
        line-height: 22px;
    }
}
