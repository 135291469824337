@import "variables";

.wrapper {
  background-color: $login-background-color;
  padding: $blocks-base-v-spacing calc(#{$blocks-base-v-spacing} / 2);

  .header {
    margin-top: 10px;
    font-size: 115%;
  }

  .field {
    margin: 20px 0;

    input {
      border: 1px solid white;
      background: white;
      padding: 10px;
      font-size: 105%;
      font-weight: bold;
      font-family: $font-family;
      width: 100%;
      box-sizing: border-box;

      &::placeholder {
        opacity: .7;
      }

      &:focus {
        border: 1px solid $divider-color;
        outline: none;
      }
    }

    .login {
      cursor: pointer;
      padding: 7px 15px;
      font-size: 140%;
      text-transform: uppercase;
    }
  }

  a, a:hover, a:visited {
    color: black;
  }
}
