.wrapper {
    --swiper-theme-color: #2E2E2E;
    position: relative;

    .swiper {
        padding-bottom: 45px;
    }

    .prev, .next {
        position: absolute;
        bottom: 0;
        z-index: 20;
        cursor: pointer;
    }

    .prev {
        left: 0;
    }
    .next {
        right: 0;
    }
}
