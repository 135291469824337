@import "variables";

.wrapper {
    background: $color-bauz-light;

    .logo {
        padding-top: 10px;
    }

    .innerWrapper {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        height: $header-height;
        flex-direction: column;
        position: relative;

        @media screen and (min-width: $mobileBp) {
            height: $header-height-desktop;
        }
        .title {
            padding: $wrapper-v-padding $wrapper-h-padding $wrapper-v-padding $wrapper-h-padding;
            flex: 1 0 auto;

            strong {
                margin-right: 10px;
            }
        }

        .loading {
            display: flex;
            flex-direction: row;
            align-items: center;

            .spinner {
                margin-right: 5px;
            }

            .text {
                margin-right: 10px;
            }
        }

        .closeButton {
            color: $close-button-color;
            cursor: pointer;
            position: absolute;
            top: calc((#{$header-height} - 24px)/2);
            right: 24px;

            @media screen and (min-width: $mobileBp) {
                top: calc((#{$header-height-desktop} - 24px)/2);
            }

            svg {
                width: 24px;
            }
        }
    }
}
