@import "variables";
$progress-bar-height: 2px;

.wrapper {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.75px;
    .bold {
        font-family: $font-maax-medium;
    }
    .remaining, .active {
        .barContainer {
            padding: 20px 0;
        }
        .bgBar {
            width: 100%;
            background: $color-bauz-lightgray;
            height: $progress-bar-height;

            .activeBar {
                background: $color-bauz-red;
                height: $progress-bar-height;
                position: relative;

                .icon {
                    box-sizing: content-box;
                    position: absolute;
                    right: 0;
                    padding: 4px;
                    background: white;
                    transform: translateY(-50%);
                }
            }
        }
    }

    .active {
        .bgBar {
            .activeBar {
                background: $color-success-green;
            }
        }
    }
    .greenText {
        font-size: 17px;
        color: $color-success-green;
    }
}

.heavyShipping {
    margin-bottom: 4px;

    .bold {
        font-size: 17px;
    }
}
