@import "../variables";

.wrapper {
  width: 100%;
  margin-bottom: $totals-v-spacing;

  .header {
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: row;

    .title {
      letter-spacing: -0.75px;
      display: inherit;
    }
  }

  .body {
      margin-top: 6px;
      width: 100%;

    .input {
      position: relative;

      input {
        box-sizing: border-box;
        border: 2px solid $color-bauz-lightgray;
        width: 100%;
        height: 58px;
        border-radius: 15px;
          padding-top: 0;
          padding-bottom: 0;
        padding-inline: 18px;

        &::placeholder {
          color: $color-bauz-gray;
        }

        &:focus {
          outline: none;
          border-color: $color-bauz-red;
        }
      }

      .button {
        position: absolute;
        right: 0;
        padding-right: 18px;
          padding-top: 22px;
          padding-bottom: 22px;
        cursor: pointer;
          border: none;
          background: none;
          font-size: 19px;
          line-height: 20px;
          color: $color-bauz-red;
          letter-spacing: -0.57px;
          font-family: $font-maax-medium;
      }
    }
  }

    .toggle {
        height: 15px;
        transition: all 0.3s ease;

        &.opened {
            transform: rotate(90deg);
        }

        &.closed {
            transform: rotate(270deg);
        }
    }
}
