@import "../variables.scss";

.wrapper {
  display: flex;
  justify-items: flex-start;
  padding-top: 5px;

  .qty {
    border: 2px solid $color-bauz-lightgray;
    padding: 0;
    font-size: 17px;
    border-radius: 15px;
  }

  input {
    border: none;
    width: 40px;
    text-align: center;
    font-family: $font-maax-medium;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      letter-spacing: -0.51px;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    background: none;
    padding: 10px;
    font-size: 17px;
    line-height: 17px;
    font-family: $font-maax-medium;
    cursor: pointer;

    &:disabled,
    &[disabled] {
      color: $color-bauz-lightgray;
      cursor: not-allowed;
    }
  }
}
